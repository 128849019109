import React, { useState, useEffect, useMemo, useContext } from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout/Layout.component';
import Img from "gatsby-image"
import Seo from '../components/Seo.component'
import CartModal from '../components/cartModal/CartModal.component'
// import { ColorOptionPicker } from "./components/"
import { Thumbnail, OptionPicker, OptionPickerDark } from "./components"
import { prepareVariantsWithOptions, prepareVariantsImages } from "./utilities"
import { useAddItemToCart } from "plugins/gatsby-theme-shopify-manager"
import { Button, ButtonLink } from '../components/buttons/Button.component';
import Breadcrumbs from '../components/breadcrumbs/Breadcrumbs.component';
import WordRotate from '../components/wordRotate/WordRotate.component';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import fbTrack from "utils/facebook";
import { CarouselPics } from '../components/carousel/Carousel.component';
import SocialImgs from '../components/socialImgs/SocialImgs.component';
import SectionIcons from '../components/sectionIcons/SectionIcons.component';
import Minus from '../images/minus.inline.svg';
import Plus from '../images/plus.inline.svg';
import EmailCapture from "../components/emailCapture/EmailCapture.component";
import './product.styles.scss';

//custom global state test
import { GlobalStateContext } from '../context/GlobalContextProvider';

const ProductPage = ({ data: { shopifyProduct: product, markdownRemark }, location }) => {

  const state = useContext(GlobalStateContext) || { variantColor: '' };
  const { frontmatter } = markdownRemark;
  const productDesc3 = frontmatter.productDesc3;
  const productDesc4 = frontmatter.productDesc4;
  const selectCore1 = frontmatter.selectCore1;
  const socialImg1 = frontmatter.socialImg1;
  const socialImg2 = frontmatter.socialImg2;
  const socialImg3 = frontmatter.socialImg3;
  const socialImg4 = frontmatter.socialImg4;

  const carouselData = [
    {img: frontmatter.carouselPic1.childImageSharp.fluid, alt: 'Applecore organized on edge of desk'},
    {img: frontmatter.carouselPic2.childImageSharp.fluid, alt: 'Applecore organized with speaker on desk'},
    {img: frontmatter.carouselPic3.childImageSharp.fluid, alt: 'Applecore wire organizer on desk'},
    {img: frontmatter.carouselPic4.childImageSharp.fluid, alt: 'Applecore clean wrap charging'},
    {img: frontmatter.carouselPic5.childImageSharp.fluid, alt: 'Applecore router organized'},
    {img: frontmatter.carouselPic6.childImageSharp.fluid, alt: 'Applecore wire wrapped in plug'},
  ]

  const [quantity, setQuantity] = useState(1);
  const [listStyle, setListStyle] = useState(0);

  /** TODO  *
   * make color picker background color equal to SKU, make SKU hex color
   * SKU format: applecore first three digits, size next, then 6 digit color
   * ex: APL-L-625AB4
  */
  // const skus = product.variants[0];
  // console.log({skus});

  // const colors = product.options.find(
  //   option => option.name.toLowerCase() === "color"
  // ).values

  // const sizes = product.options.find(
  //   option => option.name.toLowerCase() === "size"
  // ).values;

  // console.log("Size", size)

  // const getSizes = (size) => {
  //   if(size === null) return [];
  //   return product.options.find(
  //     option => option.name.toLowerCase() === "size"
  //   ).values;
  // }

  // const sizes = getSizes(size);

  // let sizesTemp;

  // if(size){
  //   sizesTemp = product.options.find(
  //     option => option.name.toLowerCase() === "size"
  //   ).values;
  // } else {
  //   sizesTemp = [];
  // }

  // const sizes = sizesTemp;
 

  const variants = useMemo(() => prepareVariantsWithOptions(product.variants), [
    product.variants,
  ])


  const images = useMemo(() => prepareVariantsImages(variants, "color"), [
    variants,
  ])

  if (images.length < 1) {
    throw new Error("Must have at least one product image!")
  }

  const addItemToCart = useAddItemToCart()
  const [variant, setVariant] = useState(variants[0])
  const [color, setColor] = useState(variant.color)
  const [size, setSize] = useState(variant.size || null)
  const [addedToCartMessage, setAddedToCartMessage] = useState(null)

  const colors = product.options.find(
    option => option.name.toLowerCase() === "color"
  ).values

  const sizes = size ? (product.options.find(
    option => option.name.toLowerCase() === "size"
  ).values) : [];

  let compareAtPrice = variant.compareAtPrice;


  // Use to set color variant when also using size variant, all products must have size and color
  // useEffect(() => {
  //   const newVariant = variants.find(variant => {
  //     return variant.color === color && variant.size === size;
  //   })

  //   if (variant.shopifyId !== newVariant.shopifyId) {
  //     setVariant(newVariant)
  //   }

  // }, [color, variants, variant.shopifyId])

   // Used for size variant when also using color variant, all products must have size and color
  //  useEffect(() => {
  //   const newVariant = variants.find(variant => {
  //     return variant.size === size && variant.color === color;
  //   })

  //   if (variant.shopifyId !== newVariant.shopifyId) {
  //     setVariant(newVariant)
  //   }

  // }, [size, variants, variant.shopifyId])

  // Set color; Check for product having size variant
  useEffect(() => {
    if (size !== null){
      const newVariant = variants.find(variant => {
        return variant.color === color && variant.size === size;
      })
  
      if (variant.shopifyId !== newVariant.shopifyId) {
        setVariant(newVariant)
      }
    } else {
      const newVariant = variants.find(variant => {
        return variant.color === color
      })
  
      if (variant.shopifyId !== newVariant.shopifyId) {
        setVariant(newVariant)
      }
    }
  }, [color, variants, variant.shopifyId])

  // Set size; Check for product having size
  useEffect(() => {
    if (size !== null){
      const newVariant = variants.find(variant => {
        return variant.size === size && variant.color === color;
      })
  
      if (variant.shopifyId !== newVariant.shopifyId) {
        setVariant(newVariant)
      }
    }
  }, [size, variants, variant.shopifyId])

  //effect for changing color to option picked on home page
  useEffect(() => {
    if (state.variantColor !== undefined) {
      const checkColor = variants.find(variant => {
        return variant.color === `${state.variantColor}`
      })

      if(checkColor !== undefined){
        //console.log('setting color from global context');
        setColor(state.variantColor);
      }
    }
  }, [])

  // useEffect(() => {
  //   fetch('/.netlify/functions/quantity')
  //     .then(res => res.json())
  //     .then(console.log);
  // }, [])

  //Change active style for thumbnail image based on select option
  useEffect(() => {
    for(let i = 0; i < images.length; i++){
      if(images[i]['color'] === color){
        setListStyle(i)
        break;
      }
    }
  }, [color])


  // updated selected variant if query string is present
  useEffect(() => {
    if (location.search !== ''){
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
  
      if(params?.color && params?.size){
        var paramColor = params.color.replace("_", " ");

        const newVariant = variants.find(variant => {
          return variant.size === params.size && variant.color === paramColor;
        })
    
        if (newVariant && variant.shopifyId !== newVariant?.shopifyId) {
          setVariant(newVariant)
          setColor(newVariant.color)
          setSize(newVariant.size)
        }
      }
    }

  }, [location])

  const gallery =
    images.length > 1 ? (
      <div className="gallery-grid">
        {images.map(({ src, color }, index) => (
          <Thumbnail key={color} src={src} onClick={() => {setListStyle(index); setColor(color)}} className={`${index === listStyle ? 'active-img' : ''} ${variants.filter(item => item.color === color && item.size === size)[0].availableForSale ? '' : 'greyed-out-thumbnail'}`} />
        ))}
      </div>
    ) : null

  async function handleAddToCart() {
    try {
      await addItemToCart(variant.shopifyId, Number(quantity))
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
      setAddedToCartMessage("Added to your cart!")
    } catch (e) {
      setAddedToCartMessage("There was a problem adding this to your cart, please refresh the page.")
    }
  }

    // Data to be passed to SocialImg component
    const socialHeader = `Applecore in Action`;
    const socialText = (<p>Follow us using <a href="https://www.instagram.com/explore/tags/applecore/" className="accent" target="_blank" rel="noreferrer">#applecore</a> and <a href="https://www.instagram.com/explore/tags/nomoretangles/" className="accent" target="_blank" rel="noreferrer">#nomoretangles</a> to see the many uses.</p>);
    const socialData = [
      { 
        socialImg: socialImg1,
        alt: `Applecore Instagram picture 1`,
        link: `https://www.instagram.com/p/CJY2PlZMEMn/`
      },
      {
        socialImg: socialImg2,
        alt: `Applecore Instagram picture 2`,
        link: `https://www.instagram.com/p/CIybZpFsgG7/`
      },
      {
        socialImg: socialImg3,
        alt: `Applecore Instagram picture 3`,
        link: `https://www.instagram.com/p/CIyZNc4skqx/`
      },
      {
        socialImg: socialImg4,
        alt: `Applecore Instagram picture 4`,
        link: `https://www.instagram.com/p/CIwyyTxMbmV/`
      }
    ];

    let coverImg;
    switch(product.handle){
      case "applecore-3-pack":
        coverImg = "/img/ogApplecore3pack.png"
        break;
      case "applecore-10-pack":
        coverImg = "/img/ogApplecore10pack.png"
        break;
      case "applecore-30-pack":
        coverImg = "/img/ogApplecore30pack.png"
        break;
      case "collections":
        coverImg = "/img/ogApplecoreCollections.png"
        break;
      case "applecore-pop-box":
        coverImg = "/img/ogApplecorePopBox.png"
        break;
      default:
        coverImg = undefined;
        break;
    }

    const description = `Organize your wires with the ${product.title}. Comes in various colors and sizes to manage your cords and reduce clutter.`

  return (
    <Layout>
      <Seo 
        title={`${product.title} | Shop`} 
        description={description} 
        thumbnailImage={coverImg} 
        addedKeywords={`${product.title}`}
        url={`https://www.myapplecore.com/shop/${product.handle}/`}
      />
      <div className="anchor" id="productTop" tabIndex="-1" aria-hidden="true"></div>
      <Breadcrumbs links={[`shop`, `shop/${product.handle}`]} />
      <section className={`product-wrap`}>
      {/* {addedToCartMessage ? (
        <div className="product-added-alert" variant="primary">
          {addedToCartMessage}
    
          <button 
            className="added-to-cart-close"
            title="Close" 
            aria-label="Close"
            onClick={() => setAddedToCartMessage(null)}>
            X
          </button>
        </div>
      ) : null} */}

      <CartModal
        addedToCartMessage={addedToCartMessage}
        setAddedToCartMessage={setAddedToCartMessage}
      />

      <div className="main-product-grid">
        <div style={{maxWidth: '500px'}}>
          <div
            style={{
              border: "1px solid gray",
              padding: 2,
              marginBottom: 2,
              borderRadius: '10px',
              overflow: 'hidden'
            }}
          >
            <Img fluid={variant.image.localFile.childImageSharp.fluid} />
          </div>
          {gallery}
        </div>
        
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 style={{ marginTop: 0, marginBottom: 2 }}>{product.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
          <div>
            <div className="product-option-container">
              <div><p style={{marginTop: '1em', fontSize: '1.4rem', color: '#303030'}}>${variant.price}</p></div>
              {compareAtPrice != null && (<div><p>previously: <span style={{textDecoration: 'line-through'}}>{compareAtPrice}</span></p></div>)}

              { variant.color !== "Assorted" &&  
                <OptionPicker
                  key="Color"
                  name="Select Color:"
                  options={colors}
                  selected={color}
                  onChange={event => setColor(event.target.value)}
                />
              }
             

              {/* <ColorOptionPicker
                key="Color"
                name="Select Color:"
                options={colors}
                selected={color}
                onChange={event => setColor(event.target.value)}
              /> */}

              {/* Uncomment for size */}
              {size && 
                <OptionPicker                
                  key="Size"
                  name="Select Size:"
                  options={sizes}
                  selected={size}
                  onChange={event => setSize(event.target.value)}
                />
              }
              
            </div>
          </div>

          {!variant.availableForSale ? (
            <div>
              <p>*Item out of stock. Select a different color, or sign up to be notified when this product is available.</p>
              <EmailCapture info={`${variant.size}${variant.color}`} />
            </div>)
            : (
              <>
                <div className="item-quantity">
                  <label htmlFor="first-choose-quantity" aria-label="Choose Quantity">Quantity:</label>
                  <button onClick={() => (quantity > 1 ? setQuantity(parseInt(quantity)-1) : setQuantity(1))} aria-label="Decrease Quantity by 1"><Minus /></button>
                    <input id="first-choose-quantity" disabled={!variant.availableForSale} type="number" onChange={event => setQuantity(event.target.value)} value={quantity} min="1"/>
                  <button onClick={() => setQuantity(parseInt(quantity)+1)} aria-label="Increase Quantity by 1"><Plus /></button>
                </div>
                <div>
                <Button
                  onClick={()=>(handleAddToCart())}
                  disabled={!variant.availableForSale || (quantity <= 0)}
                  style={{marginTop: '2em'}}
                  >
                  <span onClick={() => fbTrack('track', 'AddToCart')}>Add to cart</span>
                </Button>
                </div>
              </>
            )}

         

          <div className="product-points" style={{marginBottom: '6em'}}>
            <ul>
              <li><p>Small: 1" in diameter, 1.5" tall</p></li>
              <li><p>Medium: 1.5" in diameter, 2" tall</p></li>
              <li><p>Recyclable, soft rubber material</p></li>
              <li><p>Electical cord management for everyday use</p></li>
            </ul>
          </div>

        </div>
      </div>
      </section>

      <WordRotate
          wordList={['Home Office', 'Digital Assistant', 'Gaming', 'Dorm Room', 'Nightstand', 'Home Theater', 'Cars, Vans, Trucks', 'Backpacking', 'Kitchen', 'Travel']}
          interval={3000}
      />

      <SectionIcons />

      <div className="pic-carousel">
        <CarouselPics
              items={ 
                carouselData.map(pic => (
                    <div className="">
                      <Img className="" fluid={pic.img} alt={pic.alt}/>
                    </div>
                )) }
        />  
      </div>

      <section className="section-container product-desc-container product-cta">
                <div className="render-img-wrap">
                    <div className="render-img">
                      <Img fluid={productDesc3.childImageSharp.fluid} />
                    </div> 
                </div>
                <div className="product-cta-grid">
                  <div className="cta-copy">
                    <h2>Cable management simplified</h2>
                    <p>Keep your wires accessible and organized wherever you need them. Gone are the days of cluttered desks and messy cables.</p>
                    {/* <ButtonLink toLink={`/shop/${product.handle}`} className={`cta-btn fadeInUpDelay init0`} >
                      <span>Buy Now</span>
                    </ButtonLink>   */}
                    <div className={`cta-btn`}>
                      <span ><a className="button classic-btn" href="#productTop" style={{color: '#FBFBFB'}}>Buy Now</a></span>
                    </div>
                    
                  </div>  
                </div>
      </section>

      <Tabs>
        <TabList>
          <Tab><h3 className="review-header">Reviews</h3></Tab>
          <Tab><h3 className="review-header">Why Applecore</h3></Tab>
        </TabList>
        <TabPanel>
          <section className="section-container">
            <h2 style={{margin: '1.5em auto 2em auto'}}>What others are saying about Applecore...</h2>
            <div className="reviews-tab-flex">
                <q className="product-quote">Errant, unwound, unwrapped cords drive me up the wall. I've tried so many solutions and this is easily the best yet. - Michael Oh</q>
                <q className="product-quote">AppleCore's make fantastic gifts for anyone who's getting gadgets that have cords... power cords, charging cords, headset cords...” - Jeff</q>
            </div>
            <div className="reviews-tab-flex">
                <q className="product-quote">I travel for work with a basket of computer equipment, cords, etc. This really helps me to stay organized and untangled!” - Cherity</q>
                <q className="product-quote">Very easy to use -- and so much better than traditional cord keepers if you need to lengthen and shorten regularly.” - Bab</q>
            </div>
          </section>
        </TabPanel>
        <TabPanel>
          <section className="section-container">
              <div className="product-desc2">
                <div className="product-desc2-text">
                  <h2 style={{marginTop: '1.5em'}}>Organizing your crazy cables</h2>
                  <p>If you have any electronic devices, you probably have a heap of cords that quickly become tangled and chaotic. Messy chords make it difficult to find which wires go to the correct device, can cause damage, and make your work space a headache. </p>
                  <p>Take back control over your wires. Reduce the clutter and bring peace of mind by simply organizing your cords with Applecore. Gone are the days of messy desktops, chaotic car center consoles, and pesky mismatched wires. </p>
                  <p>Applecore’s simple design allows you to wrap up your cords and cables to neatly keep them in a safe place.  Now you can focus back on your tasks with organized cables that look good and take up less space. </p>
                </div>
              
                <div className="img-wrap-desc2"><Img fluid={productDesc4.childImageSharp.fluid} /></div>
              </div>
          </section>
        </TabPanel>
      </Tabs>

      
      <section className="section-container product-desc-container">
          <div className="product-desc4">
            <div className="other-select-product-img">
              <Img className="other-img other-img-1" fluid={selectCore1.childImageSharp.fluid} />
            </div>
            <div className="other-select-option">
              <h2 style={{margin: '1.5em auto 1em 0', color: '#fafafa'}}>{product.title}</h2>
              {color !== "Assorted" &&
                <OptionPickerDark
                  key="Color"
                  name="Color:"
                  options={colors}
                  selected={color}
                  onChange={event => setColor(event.target.value)}
                />
              }
             
              {size && 
                <OptionPickerDark                
                  key="Size"
                  name="Size:"
                  options={sizes}
                  selected={size}
                  onChange={event => setSize(event.target.value)}
                />
              }
              <div className="item-quantity" style={{marginTop: '1.5em'}}>
                <label htmlFor="second-choose-quantity" aria-label="Choose Quantity" style={{color: '#fafafa'}}>Quantity:</label>
                <button onClick={() => (quantity > 1 ? setQuantity(parseInt(quantity)-1) : setQuantity(1))} className="dark-select" aria-label="Decrease Quantity by 1"><Minus /></button>
                  <input id="second-choose-quantity" disabled={!variant.availableForSale} type="number" onChange={event => setQuantity(event.target.value)} value={quantity} min="1" style={{background: '#146495', color: "#fff", borderBottom: '1px solid #fff'}}/>
                <button onClick={() => setQuantity(parseInt(quantity)+1)} className="dark-select" aria-label="Increase Quantity by 1"><Plus /></button>
              </div>

              <div>
                  <Button
                    onClick={()=>(handleAddToCart())}
                    disabled={!variant.availableForSale || (quantity <= 0)}
                    style={{marginTop: '2em'}}
                    >
                    <span onClick={() => fbTrack('track', 'AddToCart')}>Add to cart</span>
                  </Button>
              </div>
            </div>
          </div>
      </section>

      <section className="section-container other-options">
          <h2>Applecore Galore</h2>
          <p>Want more options? Select below the Applecore that best fits your needs!</p>
          <div className="product-more-options">
            <ButtonLink toLink={`/wholesale/`}  className="prod-other-btn">
              <span>Bulk Orders</span>
            </ButtonLink>
            <ButtonLink toLink={`/shop/`} className="prod-other-btn" style={{marginBottom: '0'}}>
              <span>Other Options</span>
            </ButtonLink>
          </div>
      </section>

      <SocialImgs
        socialHeader={socialHeader}
        socialText={socialText}
        socialData={socialData}
      />

    </Layout> 
  )
}

export default ProductPage

export const ProductPageQuery = graphql`
  query productPage($productId: String!) {
    shopifyProduct(id: { eq: $productId }) {
      id
      title
      handle
      descriptionHtml
      options {
        name
        values
      }
      variants {
        availableForSale
        shopifyId
        price
        sku
        selectedOptions {
          name
          value
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: {templateKey: {eq: "shop-page"} }) {
      frontmatter{
        carouselPic1 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carouselPic2 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carouselPic3 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carouselPic4 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carouselPic5 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carouselPic6 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        productDesc3 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        productDesc4 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        selectCore1 {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg1 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg2 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg3 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialImg4 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

/** Add 'compareAtPrice' in variants query if want to show discount or price change */
// variants {
//   availableForSale
//   compareAtPrice
//   shopifyId
//   price
//   sku

/** Use if figure out individual product
 * 
 *  {(() => {
        switch(product.handle){
          case "applecore-3-pack":
            return <Applecore3Pack
                      title={product.title} 
                      addedToCartMessage={addedToCartMessage}
                      setAddedToCartMessage={setAddedToCartMessage}
                      variant={variant}
                      gallery={gallery}
                      product={product}
                      colors={colors}
                      color={color}
                      setColor={setColor}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleAddToCart={handleAddToCart}
                  />;
          case "applecore-10-pack":
            return <Applecore10Pack 
                      title={product.title} 
                      addedToCartMessage={addedToCartMessage}
                      setAddedToCartMessage={setAddedToCartMessage}
                      variant={variant}
                      gallery={gallery}
                      product={product}
                      colors={colors}
                      color={color}
                      setColor={setColor}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleAddToCart={handleAddToCart}
                  />;
          case "applecore-30-pack":
            return <Applecore30Pack 
                      title={product.title} 
                      addedToCartMessage={addedToCartMessage}
                      setAddedToCartMessage={setAddedToCartMessage}
                      variant={variant}
                      gallery={gallery}
                      product={product}
                      colors={colors}
                      color={color}
                      setColor={setColor}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleAddToCart={handleAddToCart}
                  />;
          case "collections":
            return <Collections 
                      title={product.title} 
                      addedToCartMessage={addedToCartMessage}
                      setAddedToCartMessage={setAddedToCartMessage}
                      variant={variant}
                      gallery={gallery}
                      product={product}
                      colors={colors}
                      color={color}
                      setColor={setColor}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleAddToCart={handleAddToCart}
                  />;
          default:
            return <DefaultProduct
                      title={product.title} 
                      addedToCartMessage={addedToCartMessage}
                      setAddedToCartMessage={setAddedToCartMessage}
                      variant={variant}
                      gallery={gallery}
                      product={product}
                      colors={colors}
                      color={color}
                      setColor={setColor}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      handleAddToCart={handleAddToCart}
                  />;
        }
      })()}
 */